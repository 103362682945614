import React from 'react';

export const Homepage: React.FC = () => {
    return (
        <div className={"flex h-screen bg-neutral-700 items-center justify-center"}>
            <div>
                <h1 className={"text-5xl text-white mb-10 text-center"}>Was suchst du gerade?</h1>
                <div className={"grid grid-cols-6"}>
                    <a href={"https://gewinnspieler.faelixyz.tech"} className={"bg-blue-900 p-7 mr-10 rounded-2xl transition-transform hover:rotate-6"} target={"_blank"}>
                        <img className={"rounded-2xl mb-4"} src={"/logos/gift.png"} width={300} alt={"Gewinnspieler Logo"}/>
                        <h1 className={"text-center text-white text-4xl"}>Gewinnspieler</h1>
                    </a>
                    <a href={"https://my-url.website"} className={"bg-blue-900 p-7 mr-10 rounded-2xl transition-transform hover:rotate-6"} target={"_blank"}>
                        <img className={"rounded-2xl mb-4"} src={"/logos/shorten.png"} width={300} alt={"Discord Logo"}/>
                        <h1 className={"text-center text-white text-4xl"}>URL Shortener</h1>
                    </a>
                    <a href={"https://trbl.website/fromFaelixyz"} className={"bg-blue-900 p-7 mr-10 rounded-2xl transition-transform hover:rotate-6"} target={"_blank"}>
                        <img className={"rounded-2xl mb-4"} src={"/logos/troubleshooting.png"} width={300} alt={"Troubleshooting Logo"}/>
                        <h1 className={"text-center text-white text-4xl"}>Troubleshooting.Website</h1>
                    </a>
                    <a href={"https://monstercrocs.net"} className={"bg-blue-900 p-7 mr-10 rounded-2xl transition-transform hover:rotate-6"} target={"_blank"}>
                        <img className={"rounded-2xl mb-4"} src={"/logos/mc.jpg"} width={300} alt={"MonsterCrocs Logo"}/>
                        <h1 className={"text-center text-white text-4xl"}>MonsterCrocs</h1>
                    </a>
                    <a href={"https://floppagames.com"} className={"bg-blue-900 p-7 mr-10 rounded-2xl transition-transform hover:rotate-6"} target={"_blank"}>
                        <img className={"rounded-2xl mb-4"} src={"/logos/floppagames.png"} width={300} alt={"FloppaGames Logo"}/>
                        <h1 className={"text-center text-white text-4xl"}>FloppaGames</h1>
                    </a>
                    <a href={"https://pilo.host/faelixyz"} className={"bg-blue-900 p-7 mr-10 rounded-2xl transition-transform hover:rotate-6"} target={"_blank"}>
                        <img className={"rounded-2xl mb-4"} src={"https://pilo.host/pilo.jpeg"} width={300} alt={"Pilo Host"}/>
                        <h1 className={"text-center text-white text-4xl"}>Pilo Host</h1>
                    </a>

                </div>
            </div>
        </div>
    );
}
