import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Homepage } from './pages/Homepage';
import { Admin } from "./pages/Admin";

export const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Homepage /> }/>
                <Route path="/admin" element={ <Admin /> }/>
            </Routes>
        </BrowserRouter>
    )
}